<template>
  <div v-if="communitySingleton.show_section">
    <v-container
      class="pt-7 pt-md-12 mt-1 mt-xl-12 mb-1 mb-xl-12 pb-7 pb-sm-12"
      :fluid="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.width > 2299"
    >
      <v-row align="center" justify="center">
        <v-col cols="auto" class="px-sm-0 px-md-3">
          <v-img
            v-if="communitySingleton.cover !== null"
            v-bind:src="
              'https://www.lakewaycms.actstudio.xyz' +
              communitySingleton.cover.path
            "
            class="img-community"
            eager
          ></v-img>
        </v-col>
        <v-col cols="auto" class="px-sm-0 px-md-3">
          <div class="texts-community">
            <span class="titles-app semibold">
              {{ communitySingleton.card1.title }}
            </span>

            <p
              class="mt-8 mt-xl-12"
              v-html="communitySingleton.card1.text"
              style="white-space: pre-line"
            ></p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="pt-12">
      <v-row>
        <v-col class="px-0 pb-0">
          <v-img
            v-if="communitySingleton.banner !== null"
            v-bind:src="
              'https://www.lakewaycms.actstudio.xyz' +
              communitySingleton.banner.path
            "
            class="community-banner"
            eager
          ></v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid style="background-color: #f7f4eb">
      <v-row>
        <v-col class="pa-0">
          <v-row
            align="center"
            no-gutters
            v-for="(item, i) in communitySingleton.items"
            v-bind:key="i"
          >
            <v-col cols="12" md="6" :order-md="i % 2 == 0 ? 0 : 1">
              <div class="mx-auto max-width-text-1651">
                <span
                  class="
                    semibold
                    contact-us-block-home
                    titles-app
                    title-rewrite
                  "
                >
                  {{ item.value.title }}
                </span>
                <p class="mt-7 mb-0">
                  {{ item.value.text }}
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-img
                v-if="item.value.img !== null"
                v-bind:src="
                  'https://www.lakewaycms.actstudio.xyz' + item.value.img.path
                "
                class="img-item"
                eager
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "CommunityImpact",
  data() {
    return {
      communitySingleton: {
        cover: {
          path: "",
        },
        card1: {
          title: "",
          text: "",
        },
        banner: {
          path: "",
        },
      },
    };
  },
  async beforeCreate() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }

    this.communitySingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/community?q=" +
        key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.communitySingleton = res.community))
      .catch((error) => console.log(error.message));
  },
};
</script>

<style lang="scss" scoped>
$xxl: 2300px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;
.img-community {
  width: 800px;
  height: 950px;

  @media screen and(max-width: $xxl) {
    height: 650px;
    width: 500px;
  }

  @media screen and(max-width: $xl) {
    width: 350px;
    height: 500px;
  }

  @media screen and(max-width: $md) {
    width: 500px;
    height: 600px;
  }

  @media screen and(max-width: $sm) {
    width: 300px;
    height: 400px;
  }
}

.texts-community {
  margin-left: 129px;
  max-width: 936px;

  @media screen and(max-width: $xxl) {
    max-width: 700px;
  }

  @media screen and(max-width: $xl) {
    max-width: 481px;
    margin-left: 83px;
  }

  @media screen and(max-width: $md) {
    max-width: 500px;
    margin-left: 0px;
    margin-top: 20px;
  }

  @media screen and(max-width: $sm) {
    max-width: 300px;
    margin-left: 0px;
  }
}

.community-banner {
  height: 900px;

  @media screen and(max-width: $xxl) {
    height: 650px;
  }

  @media screen and(max-width: $xl) {
    height: 450px;
  }
}

.title-rewrite {
  line-height: 0.8;
}

.max-width-text-1651 {
  max-width: 900px;

  @media screen and(max-width: $xxl) {
    max-width: 600px;
  }

  @media screen and(max-width: $xl) {
    max-width: 450px;
  }
  @media screen and(max-width: $md) {
    padding: 90px 37px;
    max-width: 550px;
  }
  @media screen and(max-width: $sm) {
    padding: 65px 37px;
  }
}

.img-item{
  @media screen and(max-width: $md) {
    height: 400px;
  }

  @media screen and(max-width: $sm) {
    height: 100%;
  }
}
</style>